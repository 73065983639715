import React from "react";
import { Link } from "gatsby";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// import { isIEBrowser } from '../../../utils';

function DescTwo(props) {
  const { product } = props;

  return (
    <Tabs selectedTabClassName="show" selectedTabPanelClassName="active">
      <div className="product-details-tab product-details-extended">
        <TabList className="nav nav-pills justify-content-center">
          <Tab className="nav-item ">
            <span className="nav-link product-details-extended__heading"> Description</span>
          </Tab>

          {/* <Tab className="nav-item">
                        <span className="nav-link"> Additional information</span>
                    </Tab> */}

          <Tab className="nav-item">
            <span className="nav-link product-details-extended__heading">Shipping & Returns</span>
          </Tab>

          <Tab className="nav-item">
            {/* <span className="nav-link">Reviews ({ product.rating })</span> */}
          </Tab>
        </TabList>

        <div className="tab-content">
       

          <TabPanel className="tab-pane">
            <div className="product-desc-content product-details-extended__description">
              <div className="container">
                <h1 style={{fontSize: "1.6rem",fontWeight:  "bold"}}>Information</h1>

                <p
                  dangerouslySetInnerHTML={{ __html: product.description }}
                ></p>
              </div>
            </div>
          </TabPanel>

          <TabPanel className="tab-pane">
            <div className="product-desc-content">
              <div class="container">
                <h3>
                  <b>Delivery &amp; returns</b>
                </h3>
                <p style={{fontSize:"1.6rem"}}>
                  Delivery is only eligible for orders $500 or more and for
                  delivery addresses within an hour of our store. Call us
                  directly 614-549-6005 or email us to set up delivery
                  contact@owls614.com
                  <br />
                  <br />
                  <b>What is your return or exchange policy?</b>
                  <br />
                  We hope you’ll love every purchase, but if there is functional
                  damage to your item, you can bring your item back to the store
                  with your receipt within 7 days of your purchase. We will then
                  give you a full refund or an exchange. Otherwise, all sales
                  final.
                  <a href="/faqs">
                    {" "}
                    <b>Visit our Frequently asked questions</b>
                  </a>
                </p>
              </div>
            </div>
          </TabPanel>

          <TabPanel className="tab-pane">
            <div className="container">
              <div className="reviews">
                <h3>Reviews (2)</h3>
                <div className="review">
                  <div className="row no-gutters">
                    <div className="col-auto">
                      <h4>
                        <Link to="#">Samanta J.</Link>
                      </h4>
                      <div className="ratings-container">
                        <div className="ratings">
                          <div
                            className="ratings-val"
                            style={{ width: "80%" }}
                          ></div>
                        </div>
                      </div>
                      <span className="review-date">6 days ago</span>
                    </div>
                    <div className="col">
                      <h4>Good, perfect size</h4>

                      <div className="review-content">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Ducimus cum dolores assumenda asperiores facilis
                          porro reprehenderit animi culpa atque blanditiis
                          commodi perspiciatis doloremque, possimus, explicabo,
                          autem fugit beatae quae voluptas!
                        </p>
                      </div>

                      <div className="review-action">
                        <Link to="#">
                          <i className="icon-thumbs-up"></i>Helpful (2)
                        </Link>
                        <Link to="#">
                          <i className="icon-thumbs-down"></i>Unhelpful (0)
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="review">
                  <div className="row no-gutters">
                    <div className="col-auto">
                      <h4>
                        <Link to="#">John Doe</Link>
                      </h4>
                      <div className="ratings-container">
                        <div className="ratings">
                          <div
                            className="ratings-val"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                      <span className="review-date">5 days ago</span>
                    </div>
                    <div className="col">
                      <h4>Very good</h4>

                      <div className="review-content">
                        <p>
                          Sed, molestias, tempore? Ex dolor esse iure hic veniam
                          laborum blanditiis laudantium iste amet. Cum non
                          voluptate eos enim, ab cumque nam, modi, quas iure
                          illum repellendus, blanditiis perspiciatis beatae!
                        </p>
                      </div>

                      <div className="review-action">
                        <Link to="#">
                          <i className="icon-thumbs-up"></i>Helpful (0)
                        </Link>
                        <Link to="#">
                          <i className="icon-thumbs-down"></i>Unhelpful (0)
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </Tabs>
  );
}

export default DescTwo;
