import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { addToCart } from "../../../reducers/cart";
import { Link, StaticQuery, graphql } from "gatsby";

import StripeBanners from "./StripeBanners";
import IconBox from "../../../components/features/icon-box";
import { quantityInputs } from "../../../utils";

const ProductDetails = (props) => {
  const { product, storeMetadata, klarna_banner, metadata } = props;
  const [stock] = useState(product.quantity);

  useEffect(() => {
    quantityInputs();
  }, []);

  const addToCartHandler = () => {
    if (0 !== stock)
      props.addToCart({
        ...props.product,
        quantity: parseInt(document.querySelector("#qty").value),
        stock: stock,
      });
  };

  return (
    product && (
      <div className={"product-details"}>
        <h1 className="product-title d-flex ">{product.title}</h1>
        {product.cl_upc && (
          <div className=" d-flex mb-1">
            {" "}
            <small> Store SKU # {product.cl_upc} </small>
          </div>
        )}
        <StaticQuery
          query={graphql`
            query ratingsdetails {
              metadata {
                ratingsVisibility
              }
            }
          `}
          render={(data) =>
            data.metadata.ratingsVisibility ? (
              <div className="ratings-container">
                Rating:{" "}
                <div className="ratings">
                  <div
                    className="ratings-val"
                    style={{ width: product?.rating * 20 + "%" }}
                  ></div>
                </div>
              </div>
            ) : null
          }
        />
        {0 < Number(product.oldPrice) &&
        Number(product.oldPrice) > Number(product.price) ? (
          <div className="product-price">
            <span className="new-price">
              $
              {Number(product.price)?.toFixed(2)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
            <span className="old-price">
              $
              {Number(product.oldPrice)?.toFixed(2)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        ) : (
          <div className="product-price">
            $
            {Number(product.price)?.toFixed(2).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        )}
        {Number(product.oldPrice) - Number(product.price) > 0 && (
          <div className="d-flex">
            <span
              style={{ width: "160px" }}
              className="  saving-badge rounded text-white bg-primary pl-1 mb-1"
            >
              Save{" "}
              <b>
                {(
                  (100 * (Number(product.oldPrice) - Number(product.price))) /
                  product.oldPrice
                )?.toFixed(0)}
                %{" "}
                {` ($${(
                  Number(product.oldPrice) - Number(product.price)
                )?.toFixed(2)})`}
              </b>
            </span>
          </div>
        )}
        {/* <div className="product-content mb-1 mt-2">
            <span>
              <b>Description: </b>
            </span>
            <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
          </div> */}

        <div className="mb-5">
          {/* <b>Specifications: </b> */}
          <table className="text-left table__specs--text-sm">
            <tbody>
              {product.condition && (
                <tr>
                  <td
                    style={{
                      maxWidth: "130px",
                      width: "130px",
                      minWidth: "100px",
                    }}
                    colSpan="1"
                  >
                    <b>Condition: {"  "}</b>
                  </td>
                  <td
                    style={{
                      maxWidth: "130px",
                      width: "130px",
                      minWidth: "100px",
                    }}
                    colSpan="1"
                  >
                    {product.condition}
                  </td>
                </tr>
              )}
              {product.brand && (
                <tr>
                  <td
                    style={{
                      maxWidth: "130px",
                      width: "130px",
                      minWidth: "100px",
                    }}
                    colSpan="1"
                  >
                    <b>Brand:</b>
                  </td>
                  <td
                    style={{
                      maxWidth: "130px",
                      width: "130px",
                      minWidth: "100px",
                    }}
                    colSpan="1"
                  >
                    {product.brand}
                  </td>
                </tr>
              )}
              {product.aspects &&
                Object.keys(product.aspects).length > 0 &&
                Object.keys(product.aspects).map((item, i) => {
                  return (
                    product.aspects[item] && (
                      <tr key={i}>
                        <td
                          style={{
                            maxWidth: "130px",
                            width: "130px",
                            minWidth: "100px",
                            textAlign: "left",
                          }}
                          colSpan="1"
                        >
                          <b>{item.replace("_", " ")}:</b>
                        </td>

                        <td
                          style={{
                            maxWidth: "130px",
                            width: "130px",
                            minWidth: "100px",
                          }}
                          colSpan="1"
                        >
                          {product.aspects[item]}
                        </td>
                      </tr>
                    )
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="details-filter-row details-row-size mb-1">
          <label htmlFor="qty">Qty:</label>
          <div className="product-details-quantity">
            <input
              type="number"
              id="qty"
              className="form-control"
              defaultValue="1"
              min="1"
              max={stock}
              step="1"
              data-decimals="0"
              required
              aria-label="quantity input"
            />
          </div>
        </div>
        <div className="product-details-action mb-2">
          <button className="btn-product btn-cart" onClick={addToCartHandler}>
            <span>add to cart</span>
          </button>
        </div>
        <div className="container p-0">
          {storeMetadata.shipping.pickup?.isEnabled && (
            <div className="row">
              <div className="col-lg-4 col-sm-6 mb-0">
                <IconBox
                  boxStyle="icon-box mb-0 d-flex flex-row flex-start text-left"
                  iconClass="icon-clock-o"
                  // title="Quisque a lectus"
                  text={`<span><strong>Pickup:</strong>${
                    product.isDefaultShippingEnabled || product.isPickup
                      ? "<span class='text-black-100'> Available at our main store location.</span><div class='text-primary text-sm'>1245 Alum Creek Dr Suite A, Columbus, OH 43209</div>"
                      : "<span class='text-black-100'> Not available for this item</span>"
                  }</span>`}
                />
              </div>
            </div>
          )}

          {storeMetadata.shipping.delivery?.isEnabled && (
            <div className="row ">
              <div className="col-lg-4 col-sm-6 mb-0">
                <IconBox
                  boxStyle="icon-box mb-0 d-flex flex-row flex-start text-left"
                  iconClass="icon-truck"
                  // title="Quisque a lectus"

                  text={`<span><strong>Local Delivery:</strong><span class='text-black'>  ${
                    product.isDefaultShippingEnabled || product.isDeliverable
                      ? " up to 20 miles with a fee."
                      : " Not available for this item."
                  }</span><div class='text-primary text-sm'>See delivery related questions faqs <a class="text-black" href="/faqs">link</a>. </div></span>`}
                />
              </div>
            </div>
          )}

          {storeMetadata.shipping.shipping?.isEnabled && (
            <div className="row">
              <div className="col-lg-4 col-sm-6 mb-2">
                <IconBox
                  boxStyle="icon-box mb-0 d-flex flex-row flex-start text-left"
                  iconClass="icon-shopping-bag"
                  // title="Quisque a lectus"
                  text={`<span><strong>Shipping :</strong><span class='text-black'>${
                    product.isDefaultShippingEnabled || product.isShippable
                      ? " We ship using USPS and UPS (2-5 days)"
                      : " Not available for this item."
                  }</span></span>`}
                />
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="container p-0 ">
          <StripeBanners
            paymentMethods={metadata?.paymentMethods}
            price={product.price}
            accountId={metadata?.stripeAccount}
            klarna_img={klarna_banner}
          />
        </div>
        <div className="product-details-footer">
          <div className="social-icons social-icons-sm">
            <span className="social-label">Share:</span>
            <Link
              to={`https://www.facebook.com/sharer/sharer.php?u=${props.location.href}/`}
              className="social-icon"
              title="Facebook"
              target="_blank"
            >
              <i className="icon-facebook-f"></i>
            </Link>

            <Link
              to={`http://twitter.com/intent/tweet?text=${encodeURIComponent(
                `Hey Checkout ${product.title}`
              )};url=${encodeURIComponent(props.location.href)}/`}
              className="social-icon"
              title="Instagram"
              target="_blank"
            >
              <i className="icon-twitter"></i>
            </Link>
          </div>
        </div>
        {props.children}
      </div>
    )
  );
};

const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => dispatch(addToCart(item)),
});

export default connect(null, mapDispatchToProps)(ProductDetails);
