import React from 'react';

import { safeContent } from '../../utils';

function IconBox( props ) {
    const { boxStyle, iconClass, title, text } = props;
    return (
        <div className={ `icon-box ${boxStyle}` }>
            <span className={ `icon-box-icon` } style={{marginBottom: "0"}}>
                <i className={ iconClass }  ></i>
            </span>

            <div className="icon-box-content pl-3">
                <p className="icon-box-title text-bold text-left">{ title }</p>
                <p dangerouslySetInnerHTML={ safeContent( text ) }></p>
            </div>
        </div>
    )
}

export default React.memo( IconBox );
