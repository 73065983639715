import React, { useState, useEffect } from "react";

import { Magnifier } from "react-image-magnifiers";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { quantityInputs } from "../../../utils";

function MediaOne(props) {
  const { product, adClass = "product-gallery-vertical" } = props;

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setStatus] = useState(false);

  useEffect(() => {
    quantityInputs();
  }, []);

  function openLightBox() {
    let index = parseInt(
      document.querySelector(".product-main-image").getAttribute("index")
    );

    if (!index) {
      index = 0;
    }
    setStatus(true);
    setPhotoIndex(index);
  }

  function closeLightBox() {
    setStatus(false);
  }

  const setNextHandler = () => {
    setPhotoIndex((photoIndex) => (photoIndex + 1) % product.images.length);
  };

  const setPrevHandler = () => {
    setPhotoIndex(
      (photoIndex) =>
        (photoIndex + product.images.length - 1) % product.images.length
    );
  };

  return <>
    <div className={`product-gallery ${adClass}`}>
      <div className="row justify-content-center m-0">
        <figure className="product-main-image" index="0" aria-labelledby="product-zoom">
          {/* {product.condition ? (
            <span className="product-label label-new">
              {product.condition}
            </span>
          ) : (
            ""
          )} */}

          {product.condition ? (
            <span className="product-label label-top">
              {product.condition}
            </span>
          ) : (
            ""
          )}

          {/* {product.discount ? (
            <span className="product-label label-sale">
              {product.discount}% off
            </span>
          ) : (
            ""
          )} */}

          {0 === product.quantity ? (
            <span className="product-label label-out">Sold Out</span>
          ) : (
            ""
          )}

          <Magnifier
            imageSrc={product.images[0]?.childImageSharp?.gatsbyImageData.images.fallback.src}
            imageAlt={product.title}
            // largeImageSrc={ product.images[0].childImageSharp.fluid.src } // Optional
            dragToMove={false}
            mouseActivation="hover"
            cursorStyleActive="crosshair"
            id="product-zoom"
          />
          {/* <SmallImage  /> */}

          <button
            id="btn-product-gallery"
            className="btn-product-gallery"
            onClick={openLightBox}
          >
            <i className="icon-arrows"></i>
          </button>
        </figure>
      </div>

      <div id="product-zoom-gallery" className="product-image-gallery">
        {product.images.slice(0, 4).map((item, index) => (
          <button
            className={`product-gallery-item ${0 === index ? "active" : ""}`}
            to="#"
            data-image={item?.childImageSharp?.gatsbyImageData.images.fallback.src}
            data-zoom-image={
              product.images[index]?.childImageSharp.gatsbyImageData.images.fallback.src
            }
            key={product._id + "-" + index}
          >
            <img
              src={product.images[index]?.childImageSharp.gatsbyImageData.images.fallback.src}
              alt={`${product.title}-${index}`}
            />

          </button>
        ))}
      </div>
    </div>

    {isOpen ? (
      <Lightbox
        mainSrc={product.images[photoIndex].childImageSharp.gatsbyImageData.images.fallback.src}
        nextSrc={
          product.images[(photoIndex + 1) % product.images.length]
            .childImageSharp.gatsbyImageData.images.fallback.src
        }
        prevSrc={
          product.images[
            (photoIndex + product.images.length - 1) % product.images.length
          ].childImageSharp.gatsbyImageData.images.fallback.src
        }
        onCloseRequest={closeLightBox}
        onMovePrevRequest={setNextHandler}
        onMoveNextRequest={setPrevHandler}
      />
    ) : (
      ""
    )}
  </>;
}

export default MediaOne;
